.three-step-process {
    text-align: center;
    margin-bottom: 30px;
}

.process-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #999;
}

.process-step {
    background-color: #333;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.process-step h3 {
    color: #ffb74d;
    font-size: 20px;
    margin-bottom: 8px;
}

.process-step p {
    font-size: 16px;
    color: #b0bec5;
}

.cost-diagram {
    background-color: #222;
    color: #e0e0e0;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
    text-align: center;
}

.cost-diagram h3 {
    color: #ff6f61;
    margin-bottom: 10px;
    font-size: 18px;
}

.cost-diagram p {
    font-size: 14px;
    margin-bottom: 15px;
}

@media (max-width: 600px) {
    .cost-diagram h3 {
        font-size: 16px;
    }

    .cost-diagram p {
        font-size: 12px;
    }

    .recharts-legend-wrapper {
        font-size: 10px;
        margin-top: 5px;
    }

    .recharts-cartesian-axis-tick-value {
        font-size: 10px;
    }
}
