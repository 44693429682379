.city-summary-container {
    background-color: #1e1e1e; /* Dark background for container */
    padding: 16px;
    border-radius: 10px;
    overflow: auto; /* Allow scrolling if the table is wider than the container */
    max-width: 100%;
  }
  
  .city-summary-table {
    background-color: #1e1e1e; /* Dark background for table */
    color: #e0e0e0; /* Light gray text for better contrast */
  }
  
  .city-summary-table-head {
    background-color: #333333 !important; /* Darker background for table header */
    color: #ffffff; /* White text in the header */
  }
  
  .city-summary-header-cell {
    border-color: #444444; /* Subtle border color for table headers */
    font-weight: bold;
    text-align: center;
  }
  
  .city-summary-table-body tr {
    transition: background-color 0.3s ease;
  }
  
  .city-summary-table-body tr:hover {
    background-color: #2e2e2e; /* Slightly lighter shade on row hover */
  }
  
  .city-summary-table-cell {
    font-weight: 600;
    background-color: #1e1e1e !important;
    color: #e0e0e0 !important;
    border-color: #444 !important; /* Subtle border color for table body */
    text-align: center;
  }

  .city-summary-table-cell:hover {
    background-color: #777 !important;
    color: #e0e0e0 !important;
    border-color: #444 !important; /* Subtle border color for table body */
    text-align: center;
  }
  
  .city-summary-max-width {
    width: 100%; /* Ensures table takes up full width of container */
  }
  