.private-sample-page {
    background-color: #0b0b0b;
    color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .page-content {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    margin-top: 110px;
  }
  
  h2 {
    font-size: 2.5rem;
    color: #eaeaea;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 1rem;
    color: #b0b0b0;
    text-align: center;
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
    margin: 10px 0 5px;
    color: #eaeaea;
    display: block;
  }
  
  input[type="text"],
  input[type="number"],
  textarea,
  select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin-bottom: 15px;
    background-color: #333;
    color: #eaeaea;
  }
  
  /* Modern styling for file upload button */
  .file-upload-label {
    font-weight: bold;
    color: #eaeaea;
  }
  
  .file-upload {
    display: none;
  }
  
  .file-upload-label::after {
    content: 'Choose File';
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #333;
    color: #eaeaea;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .file-upload-label::after:hover {
    background-color: #555;
  }
  
  .payment-section {
    margin-top: 20px;
    text-align: center;
  }
  
  .payment-section button {
    background-color: #333;
    color: #eaeaea;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .payment-section button:hover {
    background-color: #555;
  }
  
  .confirmation-message {
    color: #4caf50;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }

  .required-info input[type="email"] {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #555;
    border-radius: 6px;
    background-color: #1a1a1a;
    color: #e0e0e0;
    transition: border-color 0.3s ease;
  }
  
  .required-info input[type="email"]::placeholder {
    color: #888;
  }
  
  .required-info input[type="email"]:focus {
    outline: none;
    border-color: #4caf50; /* Slight highlight on focus */
  }

  .submit-button {
    width: 100%;
    padding: 14px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #4caf50; /* Green button color */
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .submit-button:active {
    transform: scale(0.98);
  }