/* Ensure main container covers full viewport height */

.Login {
  /* background-color: black; */
  max-height: 100vh;
  background-color: #333 !important;
  text-align: center;
  font-family: monospace;
}

@media (max-width: 768px) {
  .Login {
    
  }
}

.logout-btn {
  border: grey 1px solid;
}

.logout-btn:hover {
  background-color: black !important;
  color: white !important;
}

.login-bar {
  /* background-color: black; */
  flex: 1;
  color: green;
  text-align: center;
  border: none;
  font-size: 20px;
  border-bottom: 1px solid lightgrey;
  background-color: transparent;
  width: 150px;
}

.text-login {
  font-family:monospace;
  font-size: 60px;
}

.login-button {
  background-color: transparent;
  color: black;
  width: 20vh;
  padding: 5px;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid black;
}

.login-button:hover {
  /* background-color: black;
  color: white; */
  width: 20vh;
  padding: 5px;
  margin-top: 20px;
  border-radius: 10px;
  transform: scale(1.1);      /* Scales the button to 110% of its original size */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Adds shadow to create the pop-out effect */
}

.gap {
  background-color: black;
  margin-top: 30vh !important;
}

/* Main container for the login page */
.new-login-page {
  background-color: #111; /* Light background */
  height: 100vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.new-login-container {
  position: fixed;
  top: 150px !important;
  background-color: black; /* White background for login form */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for elevation */
  padding: 40px;
  border-radius: 12px;
  width: 100%;
  height: 450px !important;
  max-width: 400px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  border: 1px #333 solid;
}

.new-login-header h1 {
  text-align: center;
  letter-spacing: 12px !important;
  font-size: 1.5rem !important;
  color: whitesmoke;
  margin-bottom: 10px;
  font-weight: bold;
}

.new-login-header h3 {
  font-size: 1.2rem;
  color: #666666;
  margin-bottom: 40px;
}

.new-login-form {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between input fields */
}

.new-login-input {
  padding: 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.new-login-input:focus {
  border-color: #999; /* Blue border on focus */
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.3); /* Subtle shadow on focus */
  outline: none;
}

.new-login-button {
  background-color: #333;
  color: #ffffff;
  padding: 15px;
  font-size: 1.1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.new-login-button:hover {
  background-color: #999;
  transform: scale(1.05); /* Slight zoom effect */
}

.new-login-error {
  color: #ff4d4d;
  font-size: 0.9rem;
  margin-top: 20px;
}

/* Loading animation styling */
.new-login-button .react-loading-icons {
  margin: 0 auto;
}

/* Subtle Fade-In Animation */
@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .new-login-container {
      padding: 20px;
      width: 90%;
  }

  .new-login-header h1 {
      font-size: 2rem;
  }

  .new-login-header h3 {
      font-size: 1rem;
  }

  .new-login-input {
      font-size: 0.9rem;
      padding: 12px;
  }

  .new-login-button {
      padding: 12px;
      font-size: 1rem;
  }
}
