/* Card styling for each geofence */
.geofence-card {
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
    color: #e0e0e0;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.geofence-card:hover {
    transform: scale(1.02);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.7);
}

.geofence-title {
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 10px;
}

.geofence-metadata {
    font-size: 0.9rem;
    color: #cccccc;
    margin-bottom: 5px;
}

.addresses-section {
    margin-top: 15px;
}

.addresses-section h4 {
    font-size: 1rem;
    color: #e1e1e1;
    margin-bottom: 5px;
}

.addresses-section ul {
    list-style-type: none;
    padding: 0;
}

.addresses-section ul li {
    color: #cccccc;
    font-size: 0.9rem;
    margin: 3px 0;
}
