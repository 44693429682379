.mine-sample-page {
    background-color: #0b0b0b; /* Dark background */
    color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-content {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    margin-top: 110px !important;
}

h2 {
    font-size: 2.5rem;
    color: #eaeaea;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
}

.description {
    font-size: 1rem;
    text-align: center;
    color: #b0b0b0;
    margin-bottom: 30px;
    line-height: 1.5;
    max-width: 800px;
}

.data-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.data-card {
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    color: #e0e0e0;
}

.data-card h3 {
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 10px;
}

.data-card p {
    font-size: 0.9rem;
    color: #cccccc;
    margin: 5px 0;
}

.data-card a {
    color: #4caf50;
    text-decoration: none;
}

.data-card a:hover {
    text-decoration: underline;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

@media (max-width: 768px) {
    .page-content {
        padding: 15px;
    }

    .data-card {
        padding: 15px;
    }

    h2 {
        font-size: 2rem;
    }

    .description {
        font-size: 0.9rem;
    }

    .data-card h3 {
        font-size: 1.3rem;
    }

    .data-card p {
        font-size: 0.85rem;
    }
}
