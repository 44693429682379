.privacy-policy-page {
  color: #fff;
  padding: 40px;
  min-height: 100vh;
  background-image: url('../../public/cad.png');
  background-size: 60% auto; /* Set the width to 60%, the height will adjust automatically */
  background-repeat: no-repeat; /* Ensure the background does not repeat */
  background-position: center 30%; /* Center the image vertically and horizontally */
  background-attachment: fixed; /* Keeps the background in a fixed position */
  padding: 20px;
}

.privacy-content {
  margin-top: 120px !important;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Add a semi-transparent black background behind the text */
  border-radius: 12px; /* Rounded corners for a modern look */
}

.privacy-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  text-align: left; /* Left-aligned for a modern feel */
  margin-bottom: 40px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Subtle text shadow for readability */
}

.privacy-section {
  margin-bottom: 60px; /* Space between sections */
}

.section-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9); /* Slightly brighter section titles */
  margin-bottom: 20px;
  text-align: left; /* Left-aligned section titles */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Subtle shadow for readability */
}

.privacy-paragraph {
  font-size: 1.1rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.85); /* Increased contrast for text */
  margin-bottom: 20px;
  text-align: justify; /* Clean text alignment for readability */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Subtle shadow for readability */
}

@media (max-width: 768px) {
  .privacy-title {
      font-size: 2rem;
      text-align: center;
  }
  
  .section-title {
      font-size: 1.5rem;
  }

  .privacy-paragraph {
      font-size: 1rem;
  }

  .privacy-policy-page {
    background-size: 100% auto; /* Increase the size of the background image on mobile */
    background-position: center 30%; /* Adjust the position on mobile for better alignment */
}
}
