.contact-page {
    background-color: #111;
    color: #fff;
    padding: 40px;
    min-height: 100vh;
  }
  
  .contact-content {
    margin-top: 120px !important;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
  }
  
  .contact-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #fff;
    text-align: left; /* Left-aligned for modern feel */
    margin-bottom: 40px;
    letter-spacing: 6px;
  }
  
  /* Cities Section */
  .cities-section {
    margin-bottom: 60px;
  }
  
  .city-list {
    display: flex;
    justify-content: space-between; /* Distribute cities evenly */
    flex-wrap: wrap;
  }
  
  .city {
    background-color: #222;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Soft shadow */
    width: 30%; /* Each city takes up about a third */
    margin-bottom: 20px; /* Spacing between cities */
    text-align: left;
  }
  
  .city h3 {
    font-size: 1.8rem;
    color: rgba(255, 255, 255, 0.85);
    margin-bottom: 10px;
  }
  
  .city p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
  }
  
  /* Email Section */
  .email-section {
    text-align: center;
  }
  
  .email-box {
    background-color: #222;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Soft shadow */
    display: inline-block;
  }
  
  .email-link {
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
    text-align: center !important;
    justify-content: center !important;
  }
  
  .email-link:hover {
    color: #f39c12; /* Slight color change on hover */
  }
  
  @media (max-width: 768px) {
    .city-list {
      flex-direction: column;
    }
  
    .city {
      width: 100%; /* Full width on mobile */
    }
  
    .contact-title {
      font-size: 2rem;
    }
  
    .email-link {
      font-size: 1rem;
    }
  }
  