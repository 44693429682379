/* General styles */
body {
  background-color: #121212;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

.pvp-container {
  width: 100%; /* Use full width of the viewport */
  margin: 0 auto;
  padding: 20px;
  margin-top: 100px; /* Slightly larger than navbar height for spacing */
}

.prometheus-description {
  text-align: center;
  font-size: 1.2rem;
  color: #cccccc;
  margin-bottom: 20px;
}

/* Grid layout for game cards */
.games-grid {
  /* background: linear-gradient(90deg, #100c26, #141029) !important; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Game card styles */
.game-card {
  background-color: #1e1e1e;
  border-radius: 15px;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.game-card:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.7);
}

.game-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-bottom: 2px solid #333333;
}

/* Game content styling */
.game-content {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.game-title {
  font-size: 1rem; /* Slightly larger for visibility */
  font-weight: 600; /* Semi-bold for emphasis */
  color: whitesmoke; /* Light gray for subtle contrast */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Gentle shadow for separation */
  padding: 8px 12px; /* Balanced internal spacing */
  background: rgba(50, 50, 50, 0.85); /* Dark semi-transparent background */
  border-radius: 6px; /* Rounded corners for smooth edges */
  margin-bottom: 0px; /* Space below the title */
  text-align: center; /* Centered title */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Subtle border for definition */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5); /* Soft shadow for depth */
  transition: transform 0.2s ease, background 0.2s ease; /* Smooth hover effect */
}

.game-odds {
  font-size: 0.9rem;
  color: #cccccc;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.odds-percentage {
  font-weight: bold;
  color: #ffffff;
}

.payout,
.total-volume {
  font-size: 0.9rem;
  color: #bbbbbb;
  text-align: center;
}

.payout strong,
.total-volume strong {
  color: #ffffff;
}

/* Buttons */
.action-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.buy-button,
.sell-button {
  flex: 1;
  padding: 5px 0;
  font-size: 0.9rem;
  font-weight: bold;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.buy-button {
  background: linear-gradient(90deg, #313231, #313231);
}

.buy-button:hover {
  background: linear-gradient(90deg, #2e7d32, #4caf50);
  transform: scale(1.05);
}

.sell-button {
  background: linear-gradient(90deg, #6a5acd, #6a5acd);
}

.sell-button:hover {
  background: linear-gradient(90deg, #b71c1c, #e53935);
  transform: scale(1.05);
}

.wager-slider {
  width: 100%; /* Full width of the card */
  appearance: none;
  height: 8px;
  background: linear-gradient(
    to right,
    #6a5acd 0%,
    #6a5acd var(--value-percent),
    #333333 var(--value-percent),
    #333333 100%
  ); /* Dynamic gradient */
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.wager-slider::-webkit-slider-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.wager-slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
}


/* Guidelines Section */
.guidelines-section {
  width: 100%;
  margin: 20px 0;
  padding: 20px 20px;
  /* background: linear-gradient(90deg, #100c26, #141029); */
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #ffffff;
}


.guidelines-header h2 {
  font-size: 1.8rem;
  color: #ffffff;
  margin-bottom: 20px;
  border-bottom: 2px solid #333333;
  padding-bottom: 10px;
}

/* Flexbox for Guidelines Row */
.guidelines-row {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap;
}

.guideline-column {
  flex: 1;
  /* max-width: 250px; */
  min-width: 200px;
  background: linear-gradient(135deg, #1a1a1a, #242424, #383838, #1a1a1a);
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.guideline-column:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
}

.guideline-icon {
  width: 50px;
  height: 50px;
  background: linear-gradient(90deg, #6a5acd, #4c79ff);
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
}

.guideline-column p {
  font-size: 1rem;
  color: #cccccc;
  line-height: 1.5;
}

.guideline-column p strong {
  color: #ffffff;
}

.transaction-status {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.transaction-status.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.transaction-status.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.transaction-status.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.transaction-status.fade-out {
  opacity: 0;
  transform: translateY(-10px);
}

.loading-bar {
  margin: 10px 0;
  width: 100%;
  height: 8px;
  background: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.loading-progress {
  width: 0;
  height: 100%;
  background: linear-gradient(90deg, #4caf50, #81c784);
  animation: loading 2s infinite linear;
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}


.game-info {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 20px 0;
}

.team-column {
  flex: 1;
  padding: 10px;
  background: #292929;
  border-radius: 8px;
  color: #f1f1f1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background 0.3s ease;
}

.team-header {
  /* text-align: center; */
  margin-bottom: 10px;
}

.team-name {
  font-size: 18px;
  font-weight: bold;
}

.team-name.highlight {
  color: #4caf50;
  font-size: 20px;
  font-weight: 700;
}

.team-details {
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 1.6;
}

.team-column:hover {
  transform: scale(1.02);
  background: linear-gradient(135deg, #2c2c2c, #3a3a3a);
}

strong {
  color: #ccc;
}

/* Button Styling */
.alpha-button {
  background-color: black; /* Blue background */
  font-weight: bold;
  color: white; /* White text */
  font-size: 1rem; /* Medium font size */
  padding: 10px 20px; /* Add padding for a better look */
  border: none; /* Remove default border */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.alpha-button:hover {
  /* background-color: #28282B; */
  transform: translateY(-2px); /* Lift the button slightly */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.alpha-button:active {
  transform: translateY(1px); /* Push effect on click */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow on click */
}

/* Popup Styling */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8); /* Dark background with transparency */
  color: white; /* White text */
  padding: 20px 30px; /* Add padding */
  border-radius: 8px; /* Rounded corners */
  font-size: 1.2rem; /* Slightly larger font size */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  z-index: 1000; /* Ensure it appears above other content */
  animation: fadePop 0.3s ease-in-out;
}

/* Fade-in animation */
@keyframes fadePop {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* Button Container */
.button-container {
  position: relative;
  width: 100%; /* Ensure it spans full width */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}