/* General Navbar Styles */
.splash-container {
  width: 100%;
  background-color: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: fixed; /* Sticks navbar to the top */
  top: 0;
  left: 0;
  padding: 10px 20px;
  border-bottom: 1px solid #333;
  color: #ffffff;
}

.splash {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

/* Logo Styles */
.splash-logo {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.splash-logo .logo {
  width: 40px;
  height: 40px;
}

.splash-logo .logo-text {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 4px;
}

/* Wallet Button */
.wallet-container {
  display: flex;
  align-items: center;
}

.wallet-button {
  padding: 8px 24px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(90deg, #6a5acd, #4c79ff);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.wallet-button:hover {
  background: linear-gradient(90deg, #4c79ff, #6a5acd);
  transform: scale(1.05);
}

.wallet-button.connected {
  background: linear-gradient(90deg, #32cd32, #228b22);
  color: #ffffff;
}

.wallet-button.connected:hover {
  background: linear-gradient(90deg, #228b22, #32cd32);
}

/* Navigation Menu */
.splash-menu {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.splash-menu li {
  margin: 0;
}

.splash-contact {
  padding: 10px 15px;
  font-size: 0.75rem;
  font-weight: bold;
  color: #ffffff;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.splash-contact:hover {
  background-color: #ffffff;
  color: #000000;
}

/* Services Dropdown */
.services-dropdown {
  position: relative;
  display: inline-block;
}

.services-dropdown button {
  background-color: transparent;
  border: none;
  padding: 10px 15px;
  color: #ffffff;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.services-dropdown button:hover {
  background-color: #444444;
  color: #ffffff;
}

.dropdown-menu {
  display: none;
  /* position: absolute; */
  top: 100%;
  left: 0;
  background-color: #333 !important;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  width: 200px;
  /* margin-top: 20px !important; */
}

.dropdown-menu.active {
  display: block;
}

.dropdown-menu li {
  list-style: none;
  margin: 10px 0;
}

.dropdown-menu li button {
  font-size: 0.75rem;
  background-color: #333;
  color: #999999;
  width: 100%;
  padding: 10px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dropdown-menu li button:hover {
  color: #ffffff;
  background-color: #444444;
}


/* Mobile Menu */
.splash-menu-icon {
  display: none;
  cursor: pointer;
}

.splash-menu-icon .hexagon {
  background-color: #111111;
  width: 50px;
  height: 50px;
  position: relative;
  transition: transform 0.5s ease, background-color 0.3s ease;
}

.splash-menu-icon .hexagon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #999999;
  transition: border-color 0.3s ease;
}

.menu-lines {
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: white;
  top: 22px;
  left: 10px;
}

.menu-lines::before,
.menu-lines::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: white;
  left: 0;
}

.menu-lines::before {
  top: -10px;
}

.menu-lines::after {
  bottom: -10px;
}

.splash-menu-icon:hover .hexagon {
  background-color: #444444;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .splash-menu {
      display: none;
      flex-direction: column;
      background-color: #121212;
      position: absolute;
      top: 70px;
      right: 0;
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      z-index: 9999;
  }

  .splash-menu.active {
      display: flex;
      margin-top: 50px !important;
  }

  .splash-menu li {
      text-align: center;
      margin: 10px 0;
  }

  .splash-menu-icon {
      display: block;
  }

  .wallet-container {
      flex-direction: column;
      align-items: flex-end;
  }
}
