.Admin {
  background-color: black;
  /* min-height: 100vh; */
  text-align: center;
  font-family: monospace;
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}
.leaflet-container {
    background: #000
}

.summary-header {
  min-width: 200px !important;
  font-weight: 900 !important;
  font-size: 15px;
  color: whitesmoke !important;
  margin-top: 5px !important;
  text-align: center;
}

/* Typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 70%;
  }
}

@keyframes blink-caret {
  from, to {
    border-right-color: transparent;
  }
  50% {
    border-right-color: green;
  }
}

/* Fading effect */
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.typing-text {
  font-family: 'Courier', monospace;
  font-size: 2rem;
  color: green;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid green;
  width: 0;
  animation: typing 3s steps(30, end), blink-caret 0.75s step-end infinite;
  display: inline-block;
  transition: opacity 1s; /* Smooth fade out effect */

}

.fade-out {
  animation: fade-out 1s forwards;
}

.typing-text.fade-out {
    opacity: 0;
}


.hidden {
  display: none;
}

/* Centering container and making text larger */
.welcome-container {
    position: fixed; /* Fixes the container position */
    top: 0; /* Aligns to the top */
    left: 0; /* Aligns to the left */
    width: 100vw; /* Takes up the full width of the viewport */
    height: 100vh; /* Takes up the full height of the viewport */
    background-color: black; /* Black background */
    display: flex; /* Flexbox to center content */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    text-align: center; /* Centers the text inside the container */
    z-index: 9999; /* Ensures it appears above other content */
}

.welcome-container.fade-to-black {
    background-color: black; /* Background color to fade to */
}

.map-special {
  height: 1000px;
}

.mt600 {
  margin-top: 400px !important;
}

.map-container {
  height: 500px;
  width: 100%;
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Soft shadow */
  overflow: hidden; /* Ensures the map stays within the rounded corners */
  border: 1px solid #777; /* Light border for a subtle frame */
}