/* Container styling */
.engagements-summary-container {
  background-color: #000;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-height: 420px;
}

/* Wrapper div for table with scroll */
.engagements-table-wrapper {
  max-height: 360px; /* Controls the scrollable height */
  overflow-y: auto;
  padding-bottom: 10px; /* Padding to prevent table content from touching bottom edge */
}

/* Table Styling */
.engagements-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #333;
}

.engagements-table thead th {
  text-align: left;
  background-color: #111; /* Darker header background */
  padding: 15px;
  color: #bbb; /* Slightly lighter text color */
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  position: sticky;
  top: 0; /* Keeps the header at the top of the scrollable area */
  z-index: 2; /* Ensure header stays above the table rows */
}

.engagements-table tbody td {
  background-color: #222;
  padding: 15px 20px;
  color: whitesmoke;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #333;
}

.text-upper {
  text-transform: uppercase;
}

/* Row striping for readability */
.engagements-table tbody tr:nth-child(even) {
  background-color: #2a2a2a;
}

/* Hover effect for interactivity */
.engagements-table tbody tr:hover {
  background-color: #444;
}

/* Status colors */
.text-red {
  color: #e74c3c;
}

.text-green {
  color: #2ecc71;
}

/* Responsive styling */
@media (max-width: 768px) {
  .engagements-summary-container {
      padding: 10px;
      max-height: 620px !important;
  }

  .engagements-table-wrapper {
      max-height: 540px; /* Adjust height for mobile */
  }

  .engagements-table thead {
      display: none;
  }

  .engagements-table tbody td {
      display: block;
      width: 100%;
      text-align: right;
      padding: 10px;
      border-bottom: 1px solid #444;
      position: relative;
  }

  .engagements-table tbody td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 10px;
      font-weight: bold;
      text-align: left;
      color: #bbb;
  }

  .engagements-table tbody tr {
      margin-bottom: 10px;
      display: block;
      border-bottom: 2px solid #333;
      padding: 10px 0;
  }

  .mobile-text {
      font-size: 14px;
  }
}

.text-red {
  color: #cc6666 !important;
}

.text-green {
  color: #5cb85c !important;
}