/* General styling */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: black;
  overflow-x: hidden;
  color: white;
}

  .home-title {
    color: white !important;
    letter-spacing: 8px;
  }

  .home-subtitle {
    color: rgba(255, 255, 255, 0.672);
  }
  
  .splash-page {
    text-align: left;
    color: #333;
    width: 100vw; /* Full width of the viewport */
    width: 100vw;
    height: 100vh;
  }

  .no-pad-smooth {
    animation: smoothShrink 1.5s ease forwards;
}

  .re-pad-smooth {
    animation: smoothExpand 1.5s ease forwards;
  }

@keyframes smoothShrink {
    from {
      padding: 20px 0; /* Start with padding */
    }
    to {
      padding: 0px; /* End at no padding */
    }
}

@keyframes smoothExpand {
  from {
    padding: 0px;
  }
  to {
    padding: 20px 0;
  }
}

.see-through {
  animation: smoothTransparent 1.5s ease forwards;
}

.reverse-see-through {
  animation: smoothSolid 1.5s ease forwards;
}

@keyframes smoothTransparent {
  from {
    opacity: initial; /* Start with padding */
  }
  to {
    opacity: 0.9; /* End at no padding */
  }
}

@keyframes smoothSolid {
from {
  opacity: initial;
}
to {
  opacity: 1;
}
}


  
  .hero-section {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: 100vh;
    text-align: center;
    background-color: transparent;
    position: relative;
    z-index: 2;
    background-color: black !important;
    opacity: 0.75 !important;
}

  .hero-content {
    top: 350px !important;
    position: relative;
  }
  
  .container {
    text-align: left;          /* Center the text content inside the container */
    --bs-gutter-x: 0;
  }

  .black {
    background-color: black;
  }
  
  .hero-section .container {
    max-width: 100%; /* Remove width restrictions */
  }
  
  .hero-section h1 {
    font-size: 4rem;
    margin: 0;
    color: #333;
  }
  
  .hero-section p {
    font-size: 1.5rem;
    margin-top: 10px;
    color: #666;
  }

  .small-text {
    font-size: 12px !important;
  }
  
  .cta-button {
    padding: 15px 40px;
    font-size: 1rem;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    cursor: pointer;
    transition: background-color 0.4s ease, transform 0.2s ease;
}
  
  .cta-button:hover {
    background-color: white;
    color: black !important;
  }
  
  .alt {
    text-align: left !important;
    font-size: 24px !important;
  }

  /* Features Section */
/* Features Section */
.features-section {
  /* opacity: 0.95 !important; */
  background-color: #111;
  padding: 60px 20px; /* Adjusted padding for smaller screens */
  display: flex;
  justify-content: center;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid that adjusts on smaller screens */
  gap: 30px; /* Slightly smaller gap for mobile */
  width: 90%;
  max-width: 1200px;
  margin: 0 auto; /* Center the grid */
}

/* Feature Card Styling */
.feature-card {
  background-color: #1a1a1a;
  padding: 20px; /* Adjusted padding for mobile */
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); /* Slightly lighter shadow for mobile */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: white;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.25); /* Hover effect */
}

.feature-icon {
  width: 60px; /* Smaller icon size for mobile */
  height: 60px;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.feature-card:hover .feature-icon {
  transform: scale(1.1);
}

.feature-card h3 {
  font-size: 1.3rem; /* Reduced size for mobile */
  margin: 15px 0;
  font-weight: bold;
}

.feature-card p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem; /* Reduced size for readability on mobile */
  line-height: 1.5;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .features-container {
      grid-template-columns: 1fr; /* Make cards stack vertically on mobile */
      gap: 20px; /* Smaller gap on mobile */
  }

  .feature-card {
      padding: 15px; /* Adjust padding for mobile screens */
  }

  .feature-icon {
      width: 50px;
      height: 50px;
  }

  .feature-card h3 {
      font-size: 1.2rem;
  }

  .feature-card p {
      font-size: 0.85rem;
  }
}

.services-header {
  background-color: #111; /* Dark background for contrast */
  padding: 10px 20px; /* Ample padding for desktop */
  display: flex;
  justify-content: center;
  /* opacity: 0.95 !important; */
}
  
/* Product Section */
.product-section {
  background-color: #111; /* Dark background for contrast */
  padding: 80px 20px; /* Ample padding for desktop */
  display: flex;
  justify-content: center;
  /* opacity: 0.95 !important; */
}

.product-container {
  width: 90%; /* Container width to ensure responsiveness */
  max-width: 1200px;
  text-align: center;
  color: white;
  margin: 0 auto;
}

.section-title {
  letter-spacing: 6px;
  font-size: 1.8rem;
  margin-bottom: 40px;
  font-weight: bold;
  color: white;
}

/* Product Highlights */
.product-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  gap: 40px;
}

.product-card {
  background-color: #111 !important; /* Dark card background */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Soft shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.product-card:hover {
  transform: translateY(-10px); /* Hover lift */
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3); /* More shadow on hover */
}

.product-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.product-card:hover .product-icon {
  transform: scale(1.1); /* Icon zoom on hover */
}

.product-card p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7); /* Lighter text color */
  line-height: 1.6;
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .product-section {
      padding: 60px 20px;
  }

  .product-container {
      width: 100%;
      padding: 0 20px;
  }

  .section-title {
      font-size: 1.5rem;
      margin-bottom: 30px;
  }

  .product-highlights {
      grid-template-columns: 1fr; /* Stacks the product cards vertically */
      gap: 20px;
  }

  .product-card {
      padding: 20px; /* Adjust padding for mobile */
  }

  .product-icon {
      width: 60px;
      height: 60px; /* Smaller icon size for mobile */
  }

  .product-card p {
      font-size: 0.9rem; /* Smaller text for mobile */
  }
}
  
/* Footer Section */
.footer {
  background-color: #111; /* Dark background for contrast */
  color: rgba(255, 255, 255, 0.8); /* Light grey text for better readability */
  padding: 40px 20px; /* Sufficient padding for desktop */
  text-align: center;
}

.footer-container {
  width: 90%; /* Responsive container width */
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer h6 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.9); /* Slightly stronger color for heading */
}

.footer p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 10px 0;
  color: rgba(255, 255, 255, 0.7);
}

.footer a {
  color: rgba(255, 255, 255, 0.9); /* Mail link color */
  text-decoration: none;
}

.footer a:hover {
  color: white;
  text-decoration: underline; /* Underline on hover */
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .footer {
      padding: 30px 15px; /* Reduced padding for mobile */
  }

  .footer h6 {
      font-size: 1rem; /* Smaller heading for mobile */
  }

  .footer p {
      font-size: 0.9rem; /* Smaller text for better fit on mobile */
  }
}

  .mr-5 {
    padding-right: 20px !important;
  }

/* Pyramid container */
.pyramid {
    position: fixed;
    top: 30%;
    left: 38.5%;
    width: 200px;
    height: 200px;
    transform-style: preserve-3d;
    perspective: 10000px; /* Increased perspective for larger size */
    z-index: -1 !important;
}

/* Pyramid Faces */
.face {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0.9;
    border: 200px solid transparent; /* Doubled the border size */
    border-bottom: 400px solid rgba(100, 100, 100, 0.75); /* Lighter gray with 0.75 opacity */
    border-top: 0px;
}

/* Front Face */
.face-front {
    transform: rotateX(30deg);
    transform-origin: 0 0;
    border-bottom-color: rgba(180, 180, 180, 0.85); /* Lighter gray for front face */
}

/* Right Face */
.face-right {
    transform-origin: 200px 0; /* Adjusted for doubled size */
    transform: rotateY(90deg) rotateX(-30deg);
    border-bottom-color: rgba(140, 140, 140, 0.75); /* Slightly darker shade for right face */
}

/* Back Face */
.face-back {
    transform-origin: 200px 0; /* Adjusted for doubled size */
    transform: rotateY(270deg) rotateX(-30deg);
    border-bottom-color: rgba(100, 100, 100, 0.75); /* Darker shade for back face */
}

/* Left Face */
.face-left {
    transform-origin: 200px 0; /* Adjusted for doubled size */
    transform: rotateY(0) rotateX(-30deg);
    border-bottom-color: rgba(160, 160, 160, 0.75); /* Slightly lighter gray for left face */
}

/* Base of the pyramid */
.face-base {
    position: absolute;
    width: 200%; /* Doubled the base width */
    height: 200%; /* Doubled the base height */
    transform: translateY(146px) rotateX(90deg); /* Adjusted for doubled size */
    border: 0;
    background: rgba(220, 220, 220, 0.85); /* Lighter gray for base with more opacity */
}


.op0 {
    opacity: 0.5;
}

.op1 {
    opacity: 1.0 !important;
    color: white !important;
}

.py50 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

.z-5 {
    /* z-index: 2 !important; */
}

@media (max-width: 768px) {
    .pyramid {
        position: fixed;
        left: -1%;
        top: 30%;
        /* transform: translateY(500px) !important; */
    }

    .home-title {
      text-align: center;
      font-size: 16px !important;
      /* max-width: 80%; */
    }

    .home-subtitle {
        font-size: 16px !important;
    }

    .text-highlight {
        text-align: center !important;
        width: 300px !important;
      }

      .mobile-bg {
        transform: translateY(-80px);
        background-color: black !important;
      }

      .text-container {
        background-color: black !important;
        padding: 40px !important;
        border: none !important;
        width: 100%;
        margin: 0 !important
    }

    .product-highlights {
      margin: 0 !important;
    }

    .container-fluid {
      padding: 0px !important;
    }

    .text-highlight {
      padding: 0;
      margin: 0;
      width: 100% !important;
      background-color: black !important;
  }
}

.text-container {
    background-color: #121212 !important;
    padding: 60px;
    /* border: 1px white solid; */
}

.center-t {
    padding-top: 200px;
    color: rgb(89, 89, 89);
}

.part1 {
  padding-top: 200px;
  color: rgb(89, 89, 89);
  margin-right: 20px !important;
}

.upside-down {
    transform: scaleX(-1) rotateZ(180deg) !important; /* Flips the text horizontally */
    color: white !important;
    /* display: inline-block; */
  }

  .text-light {
    color: rgba(255, 255, 255, 0.702) !important;
  }

  .fine {
    font-size: 12px !important;
  }

  .bw1 {
    border-top: 1px whitesmoke solid;
  }

/* Text Section */
.text-section {
  background-color: #111; /* Dark background for contrast */
  padding: 60px 0px; /* Increased padding for desktop */
  text-align: center;
  color: white;
  /* opacity: 0.95 !important; */
  width: 100% !important;
}

.text-container {
  max-width: 100%; /* Wider max width for desktop */
  margin: 0 auto;
  padding: 0 40px; /* Increased padding inside the container */
  animation: fadeIn 1s ease-in-out; /* Fade-in animation */
}

.text-highlight {
  margin-bottom: 50px; /* More space between text blocks */
}
.center {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100%; /* Ensure the card takes up the full height */
  text-align: center; /* Center the text inside */
}

.text-highlight h1 {
  font-size: 2.5rem; /* Large font size for desktop */
  font-weight: 700; /* Bolder text */
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
  letter-spacing: 1px; /* Subtle letter spacing for readability */
  transition: transform 0.3s ease, color 0.3s ease; /* Subtle hover interaction */
}

.text-highlight h1:hover {
  transform: scale(1.02); /* Slight zoom on hover */
  color: white; /* Brighter color on hover */
}

/* Remove flex from text-section for correct grid behavior */
.text-section.row {
  display: block; /* Ensure normal block layout for Bootstrap grid */
}


/* Fade-in Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease forwards;
}

.fade-in-slow {
  animation: fadeIn 1.0s ease forwards;
}

.cta-section {
  /* margin-top: 80px; */
}

/* Fade-out Animation */
@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.fade-out {
  animation: fadeOut 2s ease forwards !important;
}



/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .text-section {
      padding: 0px 0px !important; /* Increased padding for mobile */
      margin-left: 0px !important;
      margin-right: 0px !important;
      padding-bottom: 40px !important;
  }

  .text-container {
      padding: 0 !important; /* Full width for mobile */
      max-width: 100%; /* Full width */
  }

  .text-highlight h1 {
      font-size: 1.9rem; /* Adjusted font size for mobile */
      line-height: 1.5;
      margin-bottom: 30px; /* More spacing on mobile */
  }
}


/* Fade-in Animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Products Overview Section */
.products-overview-section {
  /* opacity: 0.95; */
  background-color: #111; /* Dark background for contrast */
  padding: 80px 20px; /* Ample padding for spacing */
  color: white;
  text-align: center;
}

.products-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  gap: 40px;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center !important;
}

.section-subtitle {
  font-size: 1.5rem;
  margin-bottom: 50px;
  color: rgba(255, 255, 255, 0.7);
}

/* Product Card */
.product-card-small {
  background-color: #1a1a1a; /* Dark card background */
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Soft shadow */
  display: flex;
  flex-direction: column; /* Stack icon above text */
  align-items: center; /* Center icon and text */
  text-align: center; /* Center the text */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.back {
  transform: rotateY(0deg); /* Initial state */
  animation: flipIn 0.6s ease forwards; /* Trigger animation on DOM entry */
  transform-style: preserve-3d; /* Preserve 3D space for the element */
}

/* Define keyframes for the flip animation */
@keyframes flipIn {
  from {
    transform: rotateY(90deg); /* Start rotated */
  }
  to {
    transform: rotateY(0deg); /* End at normal position */
  }
}

.flipped {
  transform: rotateY(0deg); /* Initial state */
  animation: flipOut 0.6s ease forwards; /* Trigger animation on DOM entry */
  transform-style: preserve-3d; /* Preserve 3D space for the element */
}

/* Define keyframes for the flip animation */
@keyframes flipOut {
  from {
    transform: rotateY(270deg); /* Start rotated */
  }
  to {
    transform: rotateY(360deg); /* End at normal position */
  }
}

/* Product Card */
.product-card {
  height: 420px;
  background-color: #1a1a1a; /* Dark card background */
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Soft shadow */
  display: flex;
  flex-direction: column; /* Stack icon above text */
  align-items: center; /* Center icon and text */
  text-align: center; /* Center the text */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px black solid;
}

.product-card-text {
  background-color: #111; /* Dark card background */
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Soft shadow */
  display: flex;
  flex-direction: column; /* Stack icon above text */
  align-items: center; /* Center icon and text */
  text-align: center; /* Center the text */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-10px); /* Slight lift on hover */
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3); /* More shadow on hover */
}

/* Product Icon */
.product-icon {
  margin-bottom: 20px; /* Space between icon and text */
}

.product-icon-img {
  width: 80px;
  height: 80px;
  border-radius: 50%; /* Circular icon */
}

/* Product Details */
.product-details h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.product-details p.special-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.product-details p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .product-icon-img {
      width: 60px;
      height: 60px; /* Smaller icon for mobile */
  }

  .product-details h3 {
      font-size: 1.3rem;
  }

  .product-details p.special-name {
      font-size: 1.1rem;
  }

  .product-details p {
      font-size: 0.9rem;
  }

  .mobile-small {
    padding: 20px !important;
    font-size: 40px;
  }

  .products-overview-section {
    padding:20px 10px !important;
  }
  .text-list-item {
    font-size: 24px !important;
  }
}


.special-name {
  color: whitesmoke !important;
  font-size: 18px !important;
  font-weight: bold;
  font-style: italic;
}

.spaced {
  letter-spacing: 8px;
}

.text-list-item {
  text-align: center !important;
  letter-spacing: 7px;
}