/* About Us Page */
.about-us-page {
    background-color: #111; /* Dark theme background */
    color: white;
    min-height: 100vh;
    padding: 80px 20px; /* Space for the fixed navbar */
  }
  
  .about-us-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 40px 20px;
  }
  
  .about-title {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .about-description {
    font-size: 1.25rem;
    line-height: 1.7;
    max-width: 800px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 50px;
  }
  
  .team-highlight {
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }
  
  .team-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .team-description {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.7);
    line-height: 1.6;
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in {
    opacity: 0;
    animation: fadeIn 0.7s ease-in-out forwards;
    animation-delay: 0.1s;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-title {
      font-size: 2.5rem;
    }
  
    .about-description {
      font-size: 1rem;
    }
  
    .team-title {
      font-size: 1.5rem;
    }
  
    .team-description {
      font-size: 1rem;
    }
  }
  
.about-us-content {
    width: 100%;
    justify-content: center !important;
}