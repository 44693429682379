/* General Styles */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #1a1a1a;
  color: #ffffff;
}

.pl-profile-page {
  margin-top: 160px !important;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

/* Wallet Display */
.wallet-display {
  
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #00d1b2;
  word-break: break-word; /* Break long wallet addresses */
}

/* Loading and Error Messages */
.loading,
.error {
  margin-bottom: 40px;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 40px;
}

.error {
  color: #ff3860;
}

/* Table Container */
.wager-table-container {
  overflow-x: auto;
}

/* Wager Table Styles */
.wager-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 1rem;
}

.wager-table thead {
  background-color: #343a40;
}

.wager-table th {
  text-align: left;
  padding: 12px 8px;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  border-bottom: 2px solid #444;
}

.wager-table tbody tr {
  transition: background-color 0.3s;
}

.wager-table tbody tr:nth-child(even) {
  background-color: #2a2a2a;
}

.wager-table tbody tr:nth-child(odd) {
  background-color: #3a3a3a;
}

.wager-table td {
  padding: 10px 8px;
  font-size: 0.95rem;
  color: #d1d1d1;
  border-bottom: 1px solid #444;
  white-space: nowrap; /* Prevent text wrapping in table cells */
}

.wager-table tbody tr:hover {
  background-color: #444444;
}

.wager-table a {
  color: #00d1b2;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.wager-table a:hover {
  color: #02f2ce;
}

/* No Wagers Message */
.wager-table-container > div {
  text-align: center;
  font-size: 1rem;
  color: #d1d1d1;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .pl-profile-page {
    padding: 15px;
  }

  .wallet-display {
    font-size: 1rem;
  }

  .wager-table th,
  .wager-table td {
    font-size: 0.9rem;
    padding: 8px 6px;
  }

  .loading,
  .error {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .pl-profile-page {
    padding: 10px;
    margin-top: 120px;
  }

  .wallet-display {
    font-size: 0.95rem;
  }

  .wager-table th,
  .wager-table td {
    font-size: 0.85rem;
    padding: 6px 4px;
  }

  .wager-table {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .pl-profile-page {
    margin-top: 100px;
    padding: 8px;
  }

  .wallet-display {
    font-size: 0.9rem;
  }

  .loading,
  .error {
    font-size: 0.85rem;
  }

  .wager-table th,
  .wager-table td {
    font-size: 0.8rem;
    padding: 4px 3px;
  }

  .wager-table-container {
    margin-top: 10px;
  }
}

/* Animations */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loading {
  font-size: 1.5rem;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(90deg, #4b0082, #800080, #9932cc, #4b0082);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  animation: bounce 2s infinite, gradient 4s infinite;
  display: inline-block;
}
