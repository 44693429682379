/* Base Styling */
.App {
  font-family: monospace;
  min-height: 100vh;
  background: white;
  width: 100vw;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  color: gray !important;
  text-decoration: none !important;
}

/* Path Styling */
path {
  pointer-events: all;
}
path:hover {
  opacity: 0.5;
  cursor: pointer;
}

/* Navbar Styling */
.nav {
  background-color: #fefefe !important;
}

.sticky-nav {
  position: sticky;
  top: 0;
}

.nav-title {
  color: black !important;
  font-weight: 600 !important;
  letter-spacing: 12px !important;
  font-size: 20px;
}

.nav-subtitle {
  color: black !important;
  font-weight: 400 !important;
  letter-spacing: 2px !important;
  font-size: 11px !important;
}

/* On mobile, hide the navbar items until toggled */
.navbar-nav {
  text-align: center; /* Center menu items on mobile */
  color: #333;
}

@media (max-width: 768px) {
  /* Ensure the toggle button is styled properly on mobile */
  .navbar-toggler {
    border-color: transparent;
    /* outline: black solid; */
  }

  /* Adjust button spacing and layout for mobile */
  .navbar-collapse {
    text-align: center;
  }

  .navbar-collapse .nav-item {
    margin: 10px 0;
  }
}

/* Center the brand content on mobile */
@media (max-width: 768px) {
  .navbar-content {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: center;    /* Center-align the items */
    text-align: center;     /* Center-align the text */
  }

  .nav-title, .nav-subtitle {
    margin-bottom: 0.5rem;
  }

  /* Adjust the hamburger menu icon color to black for visibility */
/* Customize the hamburger menu icon to black */
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 24 24'%3E%3Cpath d='M3 6h18v2H3zm0 5h18v2H3zm0 5h18v2H3z'/%3E%3C/svg%3E");
    background-size: 1.5rem 1.5rem; /* Adjust size */
    background-repeat: no-repeat;
    background-position: center;
    background-color: #333 !important;
    border-radius: 4px;
}


  .navbar-toggler {
    border-color: transparent; /* Remove border around the toggle button */
  }
}


/* Main Content */
.main-content {
  padding-top: 130px;
  width: 100%;
}

.main-content-2 {
  padding-top: 90px;
  width: 100%;
}

/* Typography */
.bold {
  font-weight: 600;
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.text-green {
  color: green !important;
}

.text-red {
  color: #cc0100 !important;
}

/* Buttons */
.modern-button {
  background-color: black;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modern-button:hover {
  transform: scale(1.05);
}

.modern-button:active {
  background-color: #3e8e41;
}

.modern-button.disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
  box-shadow: none;
}

/* Row and Container Styling */
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  --bs-gutter-x: 0px !important;
}

.container {
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* Input Styling */
input:focus, input.form-control:focus {
  outline: none !important;
  box-shadow: none;
}

.filter-input {
  margin: 5px;
  height: 24px;
  max-width: 120px;
  font-size: 14px;
  border: none;
  background-color: #333;
  border-radius: 4px;
  color: white;
}

/* Slider Styling */
.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 300px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.slider-button {
  position: absolute;
  top: 50%;
  background-color: transparent;
  color: black;
  font-size: 5rem;
  padding: 10px;
  border: none;
  cursor: pointer;
  z-index: 2;
  border-radius: 8px;
}

.slider-button:hover {
  color: #445242 !important;
}

.prev-engagement,
.next-engagement {
  width: 300px;
  height: 150px;
  filter: brightness(70%);
  background-size: cover;
  background-position: center;
  transition: filter 0.3s ease, transform 0.3s ease;
  opacity: 0.7;
  cursor: pointer;
}

.prev-engagement:hover,
.next-engagement:hover {
  filter: brightness(100%);
  transform: scale(1.05);
}

.slide-image {
  max-width: 80%;
  height: auto;
  transition: transform 0.3s ease, opacity 0.5s ease-in-out;
  border-radius: 10px;
  opacity: 0;
}

.slide-image:hover {
  transform: scale(1.05);
}

.slide-image.on-load {
  opacity: 1;
}

/* Table Styling */
.scrollable-table {
  max-height: 580px;
  overflow-y: auto;
  max-width: 95% !important;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.scrollable-table table {
  width: 100%;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table tbody {
  height: inherit;
}

/* Summary Styling */
.summary-header {
  font-weight: 900;
  font-size: 15px;
  color: whitesmoke !important;
  text-align: center;
}

/* Data Header */
.data-header {
  font-weight: 900 !important;
  font-size: 25px;
  background-color: black;
  color: whitesmoke;
  width: 100%;
  margin-top: 0 !important;
  text-align: center;
  padding-top: 50px !important;
  position: fixed;
  z-index: 100;
}

/* Engagement Cards */
.engagement {
  width: 450px;
  border: 1px gray solid;
  border-radius: 8px;
}

.engagement:hover {
  border: 2px gray solid;
}

/* Separation Divs */
.separation {
  height: 50px;
}

.separation-xl {
  height: 300px;
}

.only-mobile {
  display: none;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .filter-input {
    width: 100%;
  }

  .slider {
    flex-direction: column;
    height: auto;
  }

  .slide-image {
    width: 90%;
  }

  .prev-engagement, .next-engagement {
    width: 90%;
    height: 150px;
    margin-bottom: 20px;
  }

  .logout {
    width: 90%;
    margin: 10px 0;
  }

  .only-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    width: 90%;
    text-align: center;
  }

  .soft-text {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    font-family: 'Poppins', sans-serif;
  }

  .pagination-buttons {
    justify-content: center;
    gap: 10px;
  }

  /* Hide elements with 'no-mobile' class */
  .no-mobile {
    display: none !important;
  }
}

/* No-Mobile Class for Hiding Elements on Mobile */
.no-mobile {
  display: block; /* Default behavior on non-mobile devices */
}

@media (min-width: 768px) {
  .no-mobile {
    display: block !important; /* Show on larger screens */
  }
}

.mw-95 {
  max-width: 95% !important;
}
/*
/* Container for the pagination component */
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 10px;
  background-color: #222;
  border: #444 1px solid;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styling for the logo/image */
.pagination-logo {
  flex: 0 0 auto;
  padding: 10px;
}

.pagination-image {
  width: 200px;
  height: auto;
  border-radius: 8px;
}

/* Title section */
.pagination-title {
  flex: 1;
  text-align: center;
}

.pagination-text {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
}

/* Loader styling */
.pagination-loader {
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loading-text {
  margin: 0;
  font-size: 12px;
  color: #555;
}

/* Pagination button container */
.pagination-buttons-container {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
}

/* Button styles */
.pagination-buttons .pagination-btn {
  margin: 0 5px;
  padding: 10px 15px;
  color: #333 !important;
  background-color: #fff;
  border: 1px solid #ddd !important;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.pagination-buttons .pagination-btn:hover {
  background-color: #f0f0f0;
}

.pagination-buttons .pagination-btn:disabled {
  background-color: #eaeaea;
  cursor: not-allowed;
}

.pagination-buttons .selected {
  background-color: #333;
  color: white !important;
  border-color: #007bff;
}

.pagination-buttons .selected:hover {
  background-color: #0056b3;
}


/* Datatable Container */
.datatable-container {
  width: 100%;
  margin: 20px auto;
  background-color: #000;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Pagination section */
.datatable-pagination {
  margin-bottom: 20px;
}

/* Scrollable table wrapper */
.datatable-scrollable {
  max-height: 600px;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #444;
}

/* Table */
.datatable-table {
  width: 100%;
  border-collapse: collapse;
}

/* Header Styling */
.datatable-header {
  background-color: black !important;
  color: #999 !important;
  font-weight: 600;
  text-align: left;
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 2;
  white-space: nowrap;
  width: 150px; /* Set fixed width for each column */
}

/* Header Content with filter input */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Filter input */
.datatable-filter-input {
  padding: 6px;
  min-width: 150px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #333 !important;
  margin-left: 10px;
}

.datatable-filter-input:focus {
  outline: none;
  border-color: #333;
}

/* Table Cell Styling */
.datatable-cell {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  color: whitesmoke !important;
  background-color: #222 !important;
  white-space: nowrap;
  width: 150px; /* Ensure consistent width across all columns */
}

/* Even and Odd row styling for better contrast */
.datatable-row-even {
  background-color: #ffffff;
}

.datatable-row-odd {
  background-color: #f7f7f7;
}

/* Row hover effect */
.datatable-cell:hover {
  background-color: #f0f0f0;
}

/* Responsive for mobile */
@media (max-width: 768px) {
  .datatable-container {
      padding: 10px;
  }

  .datatable-filter-input {
      width: 100px;
  }

  .datatable-table {
      font-size: 14px;
  }

  .datatable-header, .datatable-cell {
      width: 120px; /* Adjust fixed width for mobile */
  }
}

.shadow-img {
  background-image: url('../../public/shadow.png');
  background-size: cover;
  background-repeat: no-repeat; /* Ensure the background does not repeat */
  background-position: center 30%; /* Center the image vertically and horizontally */
  background-attachment: fixed; /* Keeps the background in a fixed position */
}

.mh {
  
}

/* Container for the engagements grid */
.engagements-display-container {
  max-height: 600px; /* Controls max scrollable height */
  overflow-y: auto;
  padding: 20px;
  background-color: #f9f9f9; /* Light theme background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: 95%; /* Adjust width as needed */
}

/* Engagement cards grid layout */
.engagements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px; /* Space between cards */
  justify-items: center;
}

/* Individual engagement card styling */
.engagement-card {
  width: 200px;
  height: 120px;
  background-size: cover;
  background-position: center;
  background-color: #e0e0e0; /* Placeholder color while image loads */
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

/* Hover effect for cards */
.engagement-card:hover {
  transform: translateY(-5px); /* Lift effect */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .engagements-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust for smaller screens */
  }

  .engagement-card {
      width: 150px;
      height: 100px;
  }
}
