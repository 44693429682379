/* Page container styling */
.athena-page {
    background-color: #0b0b0b; /* Dark background for the entire page */
    color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Content wrapper */
.page-content {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    margin-top: 110px;
}

/* Logo and title section styling */
.title-section {
    text-align: center;
    margin-bottom: 40px;
}

/* Logo image styling */
.logo-image {
    width: 150px;
    height: auto;
    margin-bottom: 10px;
}

/* Page title */
.page-title {
    font-size: 2rem;
    color: #eaeaea;
    text-align: center;
    font-weight: 700;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Subtitle styling */
.subtitle {
    font-size: 0.9rem;
    color: #aaaaaa;
    font-weight: 400;
    margin-bottom: 15px;
}

/* Disclaimer styling */
.disclaimer {
    font-size: 0.85rem;
    color: #b0b0b0;
    font-style: italic;
    max-width: 700px;
    margin: 0 auto;
    line-height: 1.5;
    padding: 0 10px;
}

/* Person card styling */
.person-card {
    background-color: #1a1a1a; /* Slightly lighter for contrast */
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
    margin-bottom: 30px;
    color: #e0e0e0;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
}

.person-card:hover {
    transform: scale(1.02);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.7);
}

/* Name of the person */
.person-name {
    font-size: 1.6rem;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 5px;
}

/* Location and address styling */
.person-location,
.person-address {
    font-size: 0.95rem;
    color: #cccccc;
    margin-bottom: 10px;
}

/* Character analysis section */
.character-analysis {
    font-style: italic;
    color: #b0b0b0;
    font-size: 0.9rem;
    margin-top: 15px;
}

/* Interest section styling */
.interest-section {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #333;
}

/* Each interest category (e.g., Business/Organizations) */
.interest-category {
    margin-top: 15px;
}

/* Interest category title */
.interest-category h3 {
    font-size: 1.1rem;
    color: #d1d1d1;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/* List of interests */
.interest-category ul {
    list-style-type: none;
    padding: 0;
}

.interest-category ul li {
    color: #cccccc;
    font-size: 0.9rem;
    margin: 5px 0;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .page-content {
        padding: 15px;
    }

    .logo-image {
        width: 120px;
    }

    .page-title {
        font-size: 1.8rem;
    }

    .subtitle {
        font-size: 0.85rem;
    }

    .disclaimer {
        font-size: 0.8rem;
    }

    .person-card {
        padding: 20px;
        margin-bottom: 20px;
    }

    .person-name {
        font-size: 1.4rem;
    }

    .person-location,
    .person-address,
    .character-analysis {
        font-size: 0.85rem;
    }

    .interest-category h3 {
        font-size: 1rem;
    }

    .interest-category ul li {
        font-size: 0.85rem;
    }
}

/* Card styling for each user's intent data */
.intent-card {
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
    color: #e0e0e0;
    margin-bottom: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.intent-card:hover {
    transform: scale(1.02);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.7);
}

/* Table styling for user information */
.user-info-table {
    width: 100%;
    border-collapse: collapse;
    color: #f5f5f5;
}

.user-info-table th,
.user-info-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #333;
}

.user-info-table th {
    font-weight: bold;
    color: #ffffff;
    background-color: #333;
}

.user-info-table td {
    color: #cccccc;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .user-info-table,
    .user-info-table thead,
    .user-info-table tbody,
    .user-info-table th,
    .user-info-table td,
    .user-info-table tr {
        display: block;
        width: 100%;
    }

    /* Hide table headers */
    .user-info-table thead {
        display: none;
    }

    /* Styling for each row on mobile */
    .user-info-table tr {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        border: 1px solid #333;
        border-radius: 8px;
        background-color: #1a1a1a;
    }

    .user-info-table td {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        width: 100%;
        font-size: 0.9rem;
    }

    /* Show label for each cell on mobile */
    .user-info-table td::before {
        content: attr(data-label);
        font-weight: bold;
        color: #ffffff;
        width: 40%;
    }

    .user-info-table td {
        padding-left: 10px;
    }
}
